'use client';

import React from 'react';
import Image from 'next/image';
import useEmblaCarousel from 'embla-carousel-react';
import AutoScroll from 'embla-carousel-auto-scroll';
import './BannerSection.css'
import HomeGif from '@shared/assets/gifts/home.gif';
import GoogleLogo from '@/shared/assets/logos/google-logo.png';

import type { StaticImageData } from 'next/image';

type CompanyProps = {
  name: string;
  logo: StaticImageData | string;
};

const ListCompany: CompanyProps[] = [
  { name: 'google', logo: GoogleLogo },
  { name: 'google', logo: GoogleLogo },
  { name: 'google', logo: GoogleLogo },
  { name: 'google', logo: GoogleLogo },
  { name: 'google', logo: GoogleLogo },
  { name: 'google', logo: GoogleLogo },
  { name: 'google', logo: GoogleLogo },
  { name: 'google', logo: GoogleLogo },
  { name: 'google', logo: GoogleLogo },
  { name: 'google', logo: GoogleLogo },
  { name: 'google', logo: GoogleLogo },
  { name: 'google', logo: GoogleLogo },
  { name: 'google', logo: GoogleLogo },
];

const BannerSection = () => {
  const [emblaRef] = useEmblaCarousel({ loop: true }, [
    AutoScroll({ playOnInit: true, speed: 2 }),
  ]);

  return (
    <div className='xl:mb-16 mb-5'>

      <div className="relative w-full xl:h-[600px] h-[280px]">
        <Image
          src={HomeGif}
          layout="fill"
          className="object-cover"
          alt="home-gif"
        />
      </div>


      <div className="embla pt-8" ref={emblaRef}>
        <div className="embla__container">
          {ListCompany.map((company, index) => (
            <div className="embla__slide mx-[60px]" key={index}>
              <Image
                src={company.logo}
                alt={company.name}
                width={154}
                height={154}
                objectFit="contain"
                className="mx-auto"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BannerSection;
