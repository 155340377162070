// components/FeaturesSection.tsx
import React from 'react';
import {
  FileText,
  Shield,
  Database,
  Share2,
  Layout,
  Stamp,
} from 'lucide-react';

import { LucideProps } from 'lucide-react';

type Feature = {
  icon: React.ComponentType<LucideProps>;
  title: string;
  description: string;
};

const features: Feature[] = [
  {
    icon: FileText,
    title: 'Effortless e-Signatures',
    description:
      'Sign documents digitally! Choose a style, upload an image, or use a digital stamp.',
  },
  {
    icon: Shield,
    title: 'QR Code Security',
    description:
      'Each signature comes with a unique QR code to ensure your documents are secure and authentic.',
  },
  {
    icon: Database,
    title: 'Large Storage',
    description:
      'Enjoy large document storage! All your signatures and documents are safely stored in the cloud.',
  },
  {
    icon: Share2,
    title: 'Easy Document Sharing',
    description:
      'Use the "Share With Me" feature to quickly share documents with colleagues or clients in seconds.',
  },
  {
    icon: Layout,
    title: 'Ready-to-Use Templates',
    description:
      'Choose from a variety of professional templates to speed up your signing process.',
  },
  {
    icon: Stamp,
    title: 'E-Stamp & E-Material',
    description:
      "Add e-material and digital stamps directly in our editor to boost your document's validity.",
  },
];

interface FeaturesSectionProps {
  refSection?: React.RefObject<HTMLElement>;
}

const FeaturesSection: React.FC<FeaturesSectionProps> = ({ refSection }) => {
  return (
    <section
      className='pt-[64px] pb-[140px] px-4 bg-gray-50'
      data-index='features'
      ref={refSection}
    >
      <div className='max-w-7xl mx-auto'>
        <div className='flex justify-center mb-[32px]'>
          <div className='flex justify-center w-fit items-center py-[10px] px-[27px] border-[1px] rounded-full'>
            Features
          </div>
        </div>

        <h2 className='text-center mb-2'>
          <span className='text-3xl md:text-4xl font-bold'>
            Exciting features{' '}
          </span>
          <span className='text-3xl md:text-4xl font-medium italic'>that </span>
          <span className='text-3xl md:text-4xl font-bold'>make life </span>
          <span className='text-3xl md:text-4xl font-medium italic'>
            easier
          </span>
        </h2>
        <p className='text-center text-gray-600 mb-12'>
          Everything you need for digital signatures, hassle-free.
        </p>

        <div className='grid grid-cols-2 lg:grid-cols-3 gap-8'>
          {features.map((feature, index) => (
            <div key={index} className='flex flex-col items-center text-center'>
              <feature.icon className='w-16 h-16 mb-4' />
              <h3 className='text-xl font-semibold mb-2 text-[12px] sm:text-base'>
                {feature.title}
              </h3>
              <p className='text-gray-600 text-[10px] sm:text-base'>
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
