import React from 'react';
import Image from 'next/image';

import eSignDashboard from '@/shared/assets/images/e-sign-dashboard.png';

type Benefit = {
  title: string;
  description: string;
};

const benefits: Benefit[] = [
  {
    title: 'Time Efficiency',
    description:
      'Save time with our quick signing process—no need to print or scan documents!',
  },
  {
    title: 'High Security',
    description:
      'Your documents are safe with encryption and unique QR codes that guarantee authenticity.',
  },
  {
    title: 'Easy Access',
    description:
      'Access your documents anytime, anywhere with secure cloud storage—perfect for remote work!',
  },
];

interface BenefitSectionProps {
  refSection?: React.RefObject<HTMLElement>;
}

const BenefitsSection: React.FC<BenefitSectionProps> = ({ refSection }) => {
  return (
    <section
      className='py-[140px] px-4 bg-white'
      data-index='benefits'
      ref={refSection}
    >
      <div className='max-w-7xl mx-auto'>
        <div className='flex justify-center mb-[32px]'>
          <div className='flex justify-center w-fit items-center py-[10px] px-[27px] border-[1px] rounded-full'>
            Banefit
          </div>
        </div>

        <h2 className='text-center mb-2'>
          <span className='text-3xl md:text-4xl font-bold'>Advantages </span>
          <span className='text-3xl md:text-4xl font-medium italic'>of </span>

          <span className='text-3xl md:text-4xl font-bold'>
            {' '}
            using{' '}
            <span className='text-3xl md:text-4xl font-medium italic'>e</span>
            -Signature
          </span>
        </h2>
        <p className='text-center text-gray-600 mb-9'>
          Simple, fast, and secure—perfect for all your signing needs.
        </p>

        <div className='flex flex-col md:flex-row items-center justify-between md:gap-12'>
          <div className='md:w-1/2 mb-8 md:mb-0'>
            <Image
              src={eSignDashboard}
              alt='e-Sign Dashboard'
              width={400}
              height={421}
              className='rounded-xl max-h-[420px] min-h-[245px] w-full object-contain'
            />
          </div>

          <div className='md:w-1/2'>
            <ul className='space-y-6'>
              {benefits.map((benefit, index) => (
                <li key={index} className='flex items-start'>
                  <div>
                    <h3 className='text-xl font-semibold mb-1'>
                      {benefit.title}
                    </h3>
                    <p className='text-gray-600'>{benefit.description}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BenefitsSection;
